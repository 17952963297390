@media screen and (max-width: 576px) {
  .Slider {
    display: flex;
    flex-direction: column;
    height: 310px;
    max-height: 310px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

