// Partials importieren
@import 'partials/_base';
@import 'partials/_colors';
@import 'partials/_variables';


.react-joyride__tooltip button{
  outline: none !important;
}

.btn.btn-secondary {
  color: white!important;
}