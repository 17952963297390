@use "sass:map";

@import "../../../assets/scss/main";

.form-text-input {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr auto;

  label {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  input, textarea {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  .form-error-message {
    grid-row: 3;
    grid-column: 1;
  }

  .form-input-length {
    grid-row: 3;
    grid-column: 2;
    padding-left: 1.25em;
  }

  .form-input-length-placeholder {
    grid-row: 3;
    grid-column: 2;
    display: none;

    @media (min-width: map.get($grid-breakpoints, 'md')) {
      display: inline-block;
    }
  }
}