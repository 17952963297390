@import "../../../../assets/scss/partials/colors";

$primaryLight: adjust-color($primary, $lightness: 5%, $saturation: -5%);
$primaryLighter: adjust-color($primary, $lightness: 20%, $saturation: -15%);
$primaryLightest: adjust-color($primary, $lightness: 35%, $saturation:  -20%);


:root {
  --ca-primary-color-base: #{$primary};
  --ca-primary-color-light: #{$primaryLight};
  --ca-primary-color-lighter: #{$primaryLighter};
  --ca-primary-color-lightest: #{$primaryLightest};
}

:export {
  primaryColorBase: $primary;
  primaryColorLight: $primaryLight;
  primaryColorLighter: $primaryLighter;
  primaryColorLightest: $primaryLightest;
}
