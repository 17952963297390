@import "../../../assets/scss/partials/_colors";

.Slide:global(.card) {
  overflow: hidden;
  height: 300px;
  background-color: $gray-200;
  border: none;
  justify-content: center;
  align-items: center;
}

.Slide img{
  height: auto;
  width: auto;
  overflow: hidden;
  max-width: 100%;
}

@media screen and (min-width: 576px) {
  .Slide {
    margin-right: auto;
    max-height: 100%;
    overflow: auto;
  }
}
